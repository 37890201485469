@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3, h4, h5, h6 {
  color: white;
  font-weight: 700;
}

h1 {
  font-size: 4rem;
  line-height: 1.1;
}

h2 {
  font-size: 3rem;
  line-height: 1.2;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.3;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.125rem;
  line-height: 1.5;
}

@keyframes skew-x-12 {
  0% { transform: translateX(-100%) skewX(-20deg); }
  70% { transform: translateX(100%) skewX(-20deg); }
  100% { transform: translateX(100%) skewX(-20deg); } /* Pause at the end */
}

.fixed-delay-skew-animation {
  animation: skew-x-12 2s ease-in-out infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.hero-background {
  background-image: url('./images/hero.webp');
  background-size: cover;
  background-position: top 10% left 40%;
  height: 800px;
  max-height: 800px;
}

.blogSingle h1, 
.blogSingle h2, 
.blogSingle h3, 
.blogSingle h4, 
.blogSingle h5, 
.blogSingle h6 {
  color: black;
}
b,
strong {
  color: white;
}

:disabled {
  cursor: not-allowed;
  opacity: 50%;
}

.rich-text {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  font-size: large;
  font-weight: 500;
  color: black;
}

.rich-text b, .rich-text
strong {
  color: black;
}

.rich-text h1, .rich-text h2, .rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: black;
}

.rich-text p {
  margin-bottom: 1em;
}

.rich-text a {
  color: #0066cc;
  text-decoration: none;
}

.rich-text a:hover {
  text-decoration: underline;
}

.rich-text ul, .rich-text ol {
  margin-bottom: 1em;
}

.rich-text li {
  margin-bottom: 0.5em;
}

.rich-text blockquote {
  border-left: 3px solid #dadada;
  padding-left: 1em;
  margin-left: 0;
  color: #555;
}

.rich-text code {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
}

.overlay_single-header {
  z-index: 5;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 56%,
    rgba(0, 0, 0, 0.7) 93%
  );
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.overlay_single-header-gradient {
  z-index: 5;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 56%, rgba(0, 0, 0) 93%);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.pricing_header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 20%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: black;
  color: white;
  overflow-x: hidden;
}

.form_input {
  @apply w-full px-4 py-3 text-lg leading-tight transition-all duration-300 border rounded-md placeholder:text-secondary-400 bg-secondary-900 border-secondary-900;
}

.h-500 {
  height: 500px;
}

@media (min-width: 992px) {
  .lg\:h-600 {
    height: 600px;
  }
}

.h-600 {
  height: 600px;
}

.h-700 {
  height: 700px;
}

.button-loader {
  width: 16px;
  height: 16px;
  border: 3px solid currentColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.hero-phone {
  width: 10rem;
}

.hero-alt-phone {
  width: 9rem;
  top: 0.5rem;
}

@media screen and (min-width: 478px) {
  .hero-phone {
    width: 14rem;
  }

  .hero-alt-phone {
    width: 13rem;
    top: 0.5rem;
  }
}

@keyframes slideUpFadeIn {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUpFadeIn 0.5s ease-out forwards;
  animation-delay: 0.2s; /* Adjust delay as needed */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Condensed";
  src: url("/src/fonts/OpenSans_Condensed-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url("/src/fonts/OpenSans_Condensed-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url("/src/fonts/OpenSans_Condensed-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url("/src/fonts/OpenSans_Condensed-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Condensed";
  src: url("/src/fonts/OpenSans_Condensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}